import React from "react";
import { useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css";

const SwiperComponentBA = () => {
  const isSmallScreen = useMediaQuery("(max-width: 800px)");
  const isMedScreen = useMediaQuery("(max-width: 1200px)");

  return (
    <Swiper
      modules={[Pagination]}
      style={{
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.8)",
        width: isSmallScreen ? "100%" : isMedScreen ? "400px" : "750px",
        height: isSmallScreen ? "300px" : isMedScreen ? "325px" : "500px",
        marginTop: "auto",
        maxWidth: "100%",
        marginBottom: "auto",
      }}
      pagination={true}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <img
          src="/Before1.JPG"
          alt="Slide 1"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "red",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          Before
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/After1.JPG"
          alt="Slide 2"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "blue",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          After
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/Before2.jpg"
          alt="Slide 3"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "red",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          Before
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/After2.jpg"
          alt="Slide 1"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "blue",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          After
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/Before3.JPG"
          alt="Slide 2"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "red",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          Before
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/After3.JPG"
          alt="Slide 3"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "10px",
            left: "10px",
            color: "blue",
            fontSize: "24px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          After
        </div>
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperComponentBA;
