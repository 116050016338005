import React from "react";
import { useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

const SwiperComponent = () => {
  const isSmallScreen = useMediaQuery("(max-width: 800px)");
  const isMedScreen = useMediaQuery("(max-width: 1200px)");

  return (
    <Swiper
      modules={[Pagination]}
      style={{
        borderRadius: "8px",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.8)",
        width: isSmallScreen ? "100%" : isMedScreen ? "400px" : "750px",
        height: isSmallScreen ? "300px" : isMedScreen ? "325px" : "500px",
        marginTop: "auto",
        maxWidth: "100%",
        marginBottom: "auto",
      }}
      pagination={true}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide>
        <img
          src="/R1.png"
          alt="Slide 1"
          style={{ width: "100%", height: "100%" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/R2.png"
          alt="Slide 2"
          style={{ width: "100%", height: "100%" }}
        />
      </SwiperSlide>
      <SwiperSlide>
        <img
          src="/R3.png"
          alt="Slide 3"
          style={{ width: "100%", height: "100%" }}
        />
      </SwiperSlide>
    </Swiper>
  );
};

export default SwiperComponent;
